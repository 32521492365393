var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: { width: "auto", height: "auto", position: "relative" },
      attrs: { id: "jessibuca" },
      on: { mouseover: _vm.showbtn },
    },
    [
      _c(
        "div",
        {
          ref: "container",
          style:
            "position: relative;width: 100%; height:" +
            _vm.height +
            "; background:url(" +
            _vm.background +
            ") no-repeat;background-size: 100% 100%;background-color: #000;",
          attrs: { id: "container" + _vm.index },
        },
        [
          _c(
            "div",
            {
              staticClass: "buttons-box",
              staticStyle: { position: "absolute", left: "0", bottom: "0" },
              attrs: { id: "buttonsBox" + _vm.index },
            },
            [
              _c("div", { staticClass: "buttons-box-left" }, [
                !_vm.playing
                  ? _c("i", {
                      staticClass: "iconfont icon-play jessibuca-btn",
                      on: { click: _vm.playBtnClick },
                    })
                  : _vm._e(),
                _vm.playing
                  ? _c("i", {
                      staticClass: "iconfont icon-pause jessibuca-btn",
                      on: { click: _vm.pause },
                    })
                  : _vm._e(),
                _vm.isNotMute
                  ? _c("i", {
                      staticClass: "iconfont icon-audio-high jessibuca-btn",
                      on: {
                        click: function ($event) {
                          return _vm.jessibuca.mute()
                        },
                      },
                    })
                  : _vm._e(),
                !_vm.isNotMute
                  ? _c("i", {
                      staticClass: "iconfont icon-audio-mute jessibuca-btn",
                      on: {
                        click: function ($event) {
                          return _vm.jessibuca.cancelMute()
                        },
                      },
                    })
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "buttons-box-right" }, [
                !_vm.fullscreen
                  ? _c("i", {
                      staticClass: "iconfont icon-shuaxin11 jessibuca-btn",
                      on: { click: _vm.playBtnClick },
                    })
                  : _vm._e(),
                !_vm.fullscreen
                  ? _c("i", {
                      staticClass: "iconfont icon-weibiaoti10 jessibuca-btn",
                      on: { click: _vm.fullscreenSwich },
                    })
                  : _vm._e(),
                _vm.fullscreen
                  ? _c("i", {
                      staticClass: "iconfont icon-weibiaoti11 jessibuca-btn",
                      on: { click: _vm.fullscreenSwich },
                    })
                  : _vm._e(),
              ]),
            ]
          ),
        ]
      ),
      !_vm.playing && !_vm.autoplay
        ? _c(
            "div",
            { staticClass: "btn-image", style: "height:" + _vm.height },
            [
              _c("div", {
                staticClass: "btn-image-center",
                style:
                  "width:" +
                  _vm.btnimageW +
                  ";height: " +
                  _vm.btnimageH +
                  ";background:url(" +
                  _vm.btnimage +
                  ") no-repeat;background-size: 100% 100%;",
                on: { click: _vm.playBtnClick },
              }),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }