<template>
	<div class="Y-tips">
		<!--打印二维码-->
		<el-dialog class='diyModel' :title="title" :visible.sync="$attrs.isOpen" :close-on-click-modal="true" width="660px" :before-close="closeMypup">
			<div >
				<div id="divPrint" class="itWarps">
	        <template v-for="(item,dex) in datas" >
	          <div :key="dex" class="qRItem">
	            <vue-qr :text="item.qrText" :size="200"></vue-qr>
	            <p class="itName">{{ item.name }}</p>
	          </div>
	          
	        </template>
	      </div>
				<div class="btmBtn" >
		    		<el-button class="searchbtn" @click='closeMypup'> 取消</el-button>
		    		<el-button  type="primary" v-print="{id: 'divPrint',popTitle: '打印内容'}" >打印</el-button>
        </div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import vueQr from "vue-qr";
	export default {
		props: {
			datas: {
				type: Array,
				default: [],
			},
			title: {
				type: String,
				default: '二维码',
			},
		},
		components: {vueQr},
		data() {
			return {
				printContent:{
				 	id: "divPrint",
					popTitle: '打印内容',
				}
			}
		},
		computed: {},
		watch:{},
		mounted() {},
		methods: {
			closeMypup(){
				this.$emit('close');
			}
		},

	}
</script>

<style lang="scss" scoped>
.itWarps{
	.itQrs{
		display: inline-block;
		width: 24%;
		img{
			width: 100%;
		}
	}
	.itName{
	  	width: 200px;
	  	padding: 0px 16px;
	  	text-align: center;
	 	margin: 0 auto;
	}
	.qRItem {
	  display: inline-grid;
	}
	
}
.btmBtn{
	margin-top: 12px;
	text-align: right;
}
</style>