<template>
	<div class="searchTopWarp">
		<div class="sechComponents tab-Top">
			<div class="sechComponentsItem">
		        <template v-for="(it,index) of topSearch">
		            <div class="tabQueryItem" v-if="it.slotName && !it.show" :class="{'noMarginBottom':hasClassArr.indexOf(index) != -1}" :key="index">
		                <span class="lableText sinkYft" :style="`width:${it.labelWidth ? it.labelWidth : ''}px`">{{it.label?$t(it.label):it.name}}</span>
		                <div class="queryInput" :style="`width:${width}px`">
		                    <slot :name="it.slotName" :row="it"></slot>
		                </div>
		            </div>
		            <template v-else>
		                <div class="tabQueryItem" :class="{'noMarginBottom':hasClassArr.indexOf(index) != -1}" v-if="elInput.indexOf(it.type) != -1 && !it.show" :key="index">
		                    <span class="lableText sinkYft" :style="`width:${it.labelWidth ? it.labelWidth : ''}px`">{{it.label?$t(it.label):it.name}}</span>
		                    <div class="queryInput" :style="`width:${width}px`" :class='{brRed:it.notAllow}'>
		                        <el-input
		                         type="text" 
		                         :disabled="(disabled || it.disabled) ? true : false"
		                         @blur='listenBlur(index,value[it.bindKeys],it.bindKeys,it)'
		                         @clear='it.notAllow=false'
		                         :readonly="(readonly || it.readonly) ? true : false"
		                         @change="change(index,value[it.bindKeys],it.bindKeys)" 
		                         :clearable='!it.notClear'
		                         v-model="value[it.bindKeys]"
		                         :placeholder="it.placeholder ? it.placeholder : '请输入'+it.name">
		                         
		                        </el-input>
		                        <div class="inp_tips" v-if='it.notAllow'>不允许输入特殊字符。</div>
		                    </div>
		                </div>
		                <div class="tabQueryItem" :class="{'noMarginBottom':hasClassArr.indexOf(index) != -1}"  v-else-if="elSelect.indexOf(it.type) != -1 && !it.show" :key="it.label">
		                    <span class="lableText sinkYft" :style="`width:${it.labelWidth ? it.labelWidth : ''}px`">{{it.label?$t(it.label):it.name}}</span>
		                    <div class="queryInput" :style="`width:${width}px`">
		                        <el-select
		                         :multiple="it.multiple ? it.multiple : false"
		                         :multiple-limit="it.multipleLimit ? it.multipleLimit : 0"
		                         v-model="value[it.bindKeys]" 
		                         @change="change(index,value[it.bindKeys],it.bindKeys)" 
		                         :clearable='!it.notClear'
		                         :disabled="(disabled || it.disabled) ? true : false"
		                         :readonly="(readonly || it.readonly) ? true : false" 
		                         :placeholder="it.placeholder ? it.placeholder : '请选择'+it.name">
		                            <el-option :label="it.labelName ? item[it.labelName] : item.label" :value="it.valueName ? item[it.valueName] : item.value" v-for="(item,dex2) in it.option" :key="dex2"></el-option>
		                        </el-select>
		                    </div>
		                </div>
		                <div class="tabQueryItem selectTime" :class="{'noMarginBottom':hasClassArr.indexOf(index) != -1}" v-else-if="elDataPicker.indexOf(it.type) != -1 && !it.show" :key="it.label">
		                    <span class="lableText sinkYft" :style="`width:${it.labelWidth ? it.labelWidth : ''}px`">{{it.label?$t(it.label):it.name}}</span>
		                    <div class="queryInput">
		                        <el-date-picker
		                            :is-range="it.isRange"
		                            :type="it.type"
		                            :clearable='!it.notClear'
		                            v-model="value[it.bindKeys]"
		                            :picker-options="it.pickerOptions ? it.pickerOptions : {}"
		                            :disabled="(disabled || it.disabled) ? true : false"
		                            :readonly="(readonly || it.readonly) ? true : false"
		                            range-separator="至"
		                            start-placeholder="开始时间"
		                            end-placeholder="结束时间"
		                            :value-format="it.valueFormat"
		                            :default-time="it.defaultTime ? it.defaultTime : ''"
		                            @change="change(index,value[it.bindKeys],it.bindKeys)"
		                            :placeholder="it.placeholder ? it.placeholder : '请输入'+it.name">
		                        </el-date-picker>
		                    </div>
		                </div>
		                <div class="tabQueryItem selectTime" :class="{'noMarginBottom':hasClassArr.indexOf(index) != -1}" v-else-if="eltimePicker.indexOf(it.type) != -1 && !it.show" :key="it.label">
		                    <span class="lableText sinkYft" :style="`width:${it.labelWidth ? it.labelWidth : ''}px`">{{it.label?$t(it.label):it.name}}</span>
		                    <div class="queryInput" :style="`width:${width}px`">
		                        <el-time-picker
		                            :is-range="it.isRange ? it.isRange : false"
		                            v-model="value[it.bindKeys]"
		                            :picker-options="it.pickerOptions ? it.pickerOptions : {}"
		                            :disabled="(disabled || it.disabled) ? true : false"
		                            :readonly="(readonly || it.readonly) ? true : false"
		                            range-separator="至"
		                            start-placeholder="开始时间"
		                            end-placeholder="结束时间"
		                            :value-format="it.valueFormat"
		                            @change="change(index,value[it.bindKeys],it.bindKeys)"
		                            :placeholder="it.placeholder ? it.placeholder : '请输入'+it.name">
		                        </el-time-picker>
		                    </div>
		                </div>
		            </template>
		            <br v-if="(index + 1) % perLine == 0 && index != topSearch.length - 1" :key="index" />
		        </template>
		        <slot name="btnSlot"></slot>
		    </div>		
		</div>
	</div>
</template>
<script>
export default {
	components: {},
    props:{
        topSearch:{       //要创建的表单数据
            type:Array,
            default:()=>{
                return []
            },
        },
        value:{//与组件绑定的搜索条件的值
            type:Object,
            default:()=>{
                return {}
            },
        },
        disabled:{//是否禁用
            type:Boolean,
            default:false,
        },
        readonly:{//只读
            type:Boolean,
            default:false,
        },
        width:{//是否设定选择框和输入框的统一宽度
            type:Number | String,
            default:'',
        },
        perLine:{//每行多少个 默认4个
            type:Number | String,
            default:5,
        }
    },
    data(){
        return {
            hasClassArr:[],//不需要加类的下标  （加了类 就 margin-bottom:0）;
            elInput:['text','number'],//输入框类型
            elSelect:['select'],//选择框类型
            elDataPicker:['date','year','month','week','dates','daterange','monthrange','datetime','datetimerange'],//日期选择器类型
            eltimePicker:['timePicker'],//时间选择器类型
            time:"",
            notAllow:false,
        }
    },
    created(){
        this.init();
    },
    methods:{
        /**
         * 初始化转化绑定表单数据
         */
        init(){
            for(let it of this.topSearch){
                if(!it.slotName){
                    this.$set(this.value,it.bindKeys,it.defaultValue !== '' ? it.defaultValue : '');
                }
                if(it.startTimeName){
                    this.$set(this.value,it.startTimeName,'');
                }
                if(it.endTimeName){
                    this.$set(this.value,it.endTimeName,'');
                }
            }
            let len = this.topSearch.length;
            for(let i = 1;i <= (len % 4 == 0 ? 4 : len % 4 );i++){
                this.hasClassArr.push(len - i);
            }
            this.$emit('input',this.value);//触发v-model   不写现在也行  但是vue的语法标准是需要写上的
            this.$emit('initSeach',this.value);//初始化数据完成后触发的回调
        },
        /**
         * 触发change事件
         */
        change(index,value,bindKeys){
            if(this.topSearch[index].startTimeName && this.topSearch[index].endTimeName && (this.elDataPicker.indexOf(this.topSearch[index].type) != -1 || this.eltimePicker.indexOf(this.topSearch[index].type) != -1)){
                this.value[this.topSearch[index].startTimeName] = value ? value[0] : '';
                this.value[this.topSearch[index].endTimeName] = value ? value[1] : '';
            }
            if(this.topSearch[index].isChange){
                this.$emit('change',value ? value : '',bindKeys);
            }
            
            let it=this.topSearch[index];
            if(!it.changMethod) return;
    		it.changMethod(value,it)
        },
        listenBlur(index,value,bindKeys,it){},
    }
}
</script>
<style lang="scss">
.inp_tips{
	color: red;
	font-size: 12px;
	position: absolute;
	bottom: -18px;
	line-height: initial;
}
.brRed .el-input .el-input__inner{
	border:1px solid red !important;
}
.tab-Top .sechComponentsItem .tabQueryItem.selectTime .el-input__inner{
    width: 100%;
}
.sechComponentsItem{
    .tabQueryItem{
    	/*width: 25%;*/
    	padding-right: 12px;
    	display: inline-block;
        margin-bottom: 18px !important;
       .queryInput{
       		width: calc(100% - 102px);
       		display: inline-block;
       }
       .el-input__inner{
       	
       }
       .lableText{
       	display: inline-block;
       	/*width: 100px;*/
       	text-align: left;
       	padding-right: 12px;
       	font-size: 14px;
    	color: #606266;
    	font-weight: bold;
    	box-sizing: border-box;
       }
    }
    .tabQueryItem.noMarginBottom{
        margin-bottom: 0;
    }
    /*.tabQueryItem.selectTime{
    	min-width: 500px;
    }*/
    
    .tabQueryItem,.eventBtn{
    	vertical-align: text-top;
    }
    .rightBtnGroup{
    	display: inline-block;
    	margin-left: 10px;
    }
}
</style>
