<template >
  <div
    id="jessibuca"
    style="width: auto; height: auto; position: relative"
    @mouseover="showbtn"
  >
    <div
      :id="'container' + index"
      ref="container"
      :style="
        'position: relative;width: 100%; height:' +
        height +
        '; background:url(' +
        background +
        ') no-repeat;background-size: 100% 100%;background-color: #000;'
      "
    >
      <!-- @dblclick="fullscreenSwich" -->
      <div
        :id="'buttonsBox' + index"
        class="buttons-box"
        style="position: absolute; left: 0; bottom: 0"
      >
        <div class="buttons-box-left">
          <i
            v-if="!playing"
            class="iconfont icon-play jessibuca-btn"
            @click="playBtnClick"
          />
          <i
            v-if="playing"
            class="iconfont icon-pause jessibuca-btn"
            @click="pause"
          />
          <!-- <i class="iconfont icon-stop jessibuca-btn" @click="destroy" /> -->
          <i
            v-if="isNotMute"
            class="iconfont icon-audio-high jessibuca-btn"
            @click="jessibuca.mute()"
          />
          <i
            v-if="!isNotMute"
            class="iconfont icon-audio-mute jessibuca-btn"
            @click="jessibuca.cancelMute()"
          />
        </div>
        <div class="buttons-box-right">
          <!-- <span class="jessibuca-btn">{{ kBps }} kb/s</span> -->
          <!--          <i class="iconfont icon-file-record1 jessibuca-btn"></i>-->
          <!--          <i class="iconfont icon-xiangqing2 jessibuca-btn" ></i>-->
          <!-- <i
            class="iconfont icon-camera1196054easyiconnet jessibuca-btn"
            @click="jessibuca.screenshot('截图', 'png', 0.5)"
          /> -->
          <i
            v-if="!fullscreen"
            class="iconfont icon-shuaxin11 jessibuca-btn"
            @click="playBtnClick"
          />
          <i
            v-if="!fullscreen"
            class="iconfont icon-weibiaoti10 jessibuca-btn"
            @click="fullscreenSwich"
          />
          <i
            v-if="fullscreen"
            class="iconfont icon-weibiaoti11 jessibuca-btn"
            @click="fullscreenSwich"
          />
        </div>
      </div>
    </div>
    <div
      v-if="!playing && !autoplay"
      class="btn-image"
      :style="'height:' + height"
    >
      <div
        class="btn-image-center"
        @click="playBtnClick"
        :style="
          'width:' +
          btnimageW +
          ';height: ' +
          btnimageH +
          ';background:url(' +
          btnimage +
          ') no-repeat;background-size: 100% 100%;'
        "
      ></div>
    </div>
  </div>
</template>

<script>
import { Base64 } from "js-base64";
import { checkSession } from "@/api/ruge/login.js";
/* eslint-disable no-underscore-dangle */
export default {
  name: "Jessibuca",
  props: {
    index: {
      //index
      type: Number,
      default: 0,
    },
    videoUrl: {
      //播放地址
      type: String,
      default: "",
    },
    error: {
      //报错信息
      type: Function,
      default: null,
    },
    hasAudio: {
      //静音
      type: Boolean,
      default: false,
    },
    height: {
      //播放器高度
      type: String,
      default: "500px",
    },
    isFullResize: {
      //播放面即是否填充满容器
      type: Boolean,
      default: true,
    },
    autoplay: {
      //是否自动播放
      type: Boolean,
      default: false,
    },
    background: {
      //封面图
      type: String,
      default: "",
    },
    btnimage: {
      //播放器按钮图片
      type: String,
      default: "",
    },
    btnimageH: {
      //播放器按钮图片高度
      type: String,
      default: "60px",
    },
    btnimageW: {
      //播放器按钮图片宽度
      type: String,
      default: "60px",
    },
  },
  data() {
    return {
      jessibuca: null,
      playing: false,
      isNotMute: false,
      quieting: false,
      fullscreen: false,
      loaded: false, // mute
      speed: 0,
      performance: "", // 工作情况
      kBps: 0,
      btnDom: null,
      videoInfo: null,
      volume: 1,
      rotate: 0,
      vod: true, // 点播
      forceNoOffscreen: false,
    };
  },
  watch: {
    videoUrl: {
      handler(newData, oldData) {
        if (!this.autoplay && !oldData) return;
        this.play(newData);
      },
    },
  },
  mounted() {
    window.onerror = (msg) => {
      console.error(msg);
    };
    const paramUrl = decodeURIComponent(this.$route.params.url);
    this.$nextTick(() => {
      if (typeof this.videoUrl === "undefined") {
        this.videoUrl = paramUrl;
      }
      this.btnDom = document.getElementById("buttonsBox" + this.index);
      console.log(`初始化时的地址为: ${this.videoUrl}`);
      this.autoplay ? this.play(this.videoUrl) : "";
    });
  },
  destroyed() {
    if (this.jessibuca) {
      this.jessibuca.destroy();
    }
    this.playing = false;
    // this.loaded = false
    this.performance = "";
  },
  methods: {
    urlAddToken(url) {
      const currentToken = this.$store.getters.baseAuthToken;
      return `${url}?${currentToken}`;
    },
    create() {
      const options = {};
      // console.log(this.$refs.container)
      // console.log(`hasAudio  ${this.hasAudio}`)

      this.jessibuca = new window.Jessibuca(
        Object.assign(
          {
            showBandwidth: false,
            hiddenAutoPause: true, //是否开启当页面的'visibilityState'变为'hidden'的时候，自动暂停播放。
            container: this.$refs.container,
            videoBuffer: 0.3, // 最大缓冲时长，单位秒
            isResize: true,
            useMSE: true,
            isFlv: true,
            // decoder需要和index.html中的路径保持一致不然无法正常初始化
            decoder: `${
              process.env.VUE_APP_ENV === "DEV" ? "" : "/console"
            }/static/decoder.js`,
            // decoder:
            //   window.location.origin + "/static/jessibuca/decoder-pro.js",
            // text: "WVP-PRO",
            // background: "bg.jpg",
            loadingText: "加载中",
            hasAudio:
              typeof this.hasAudio === "undefined" ? true : this.hasAudio,
            debug: false,
            supportDblclickFullscreen: true, // 是否支持屏幕的双击事件，触发全屏，取消全屏事件。
            operateBtns: {
              fullscreen: false,
              screenshot: false,
              play: false,
              audio: false,
            },
            record: "record",
            isFullResize: this.isFullResize || false,
            vod: this.vod,
            forceNoOffscreen: this.forceNoOffscreen,
            isNotMute: this.isNotMute,
          },
          options
        )
      );

      // eslint-disable-next-line no-underscore-dangle
      const _this = this;
      this.jessibuca.on("load", () => {
        console.log("on load init");
      });

      this.jessibuca.on("log", (msg) => {
        console.log("on log", msg);
      });
      this.jessibuca.on("record", (msg) => {
        console.log("on record:", msg);
      });
      this.jessibuca.on("pause", () => {
        _this.playing = false;
      });
      this.jessibuca.on("play", () => {
        _this.playing = true;
      });
      this.jessibuca.on("fullscreen", (msg) => {
        console.log("on fullscreen", msg);
        _this.fullscreen = msg;
      });

      this.jessibuca.on("mute", (msg) => {
        console.log("on mute", msg);
        _this.isNotMute = !msg;
      });
      this.jessibuca.on("audioInfo", (msg) => {
        console.log("audioInfo", msg);
      });

      // this.jessibuca.on("videoInfo", function (msg) {
      //   this.videoInfo = msg;
      //   // console.log("videoInfo", msg);
      // });

      this.jessibuca.on("bps", (bps) => {
        console.log("bps", bps);
      });
      // eslint-disable-next-line no-unused-vars
      let _ts = 0;
      this.jessibuca.on("timeUpdate", (ts) => {
        // console.log('timeUpdate,old,new,timestamp', _ts, ts, ts - _ts);
        _ts = ts;
      });

      this.jessibuca.on("videoInfo", (info) => {
        console.log("videoInfo", info);
      });

      this.jessibuca.on("error", (error) => {
        console.log("error", error);
        // 如果有超时，checksession查看是否登录过期
        checkSession();
      });

      this.jessibuca.on("timeout", (e) => {
        console.log("timeout", e);
        // 如果有超时，checksession查看是否登录过期
        checkSession();
      });

      this.jessibuca.on("start", () => {
        console.log("start");
      });

      this.jessibuca.on("performance", (performance) => {
        let show = "卡顿";
        if (performance === 2) {
          show = "非常流畅";
        } else if (performance === 1) {
          show = "流畅";
        }
        _this.performance = show;
      });
      this.jessibuca.on("buffer", (buffer) => {
        console.log("buffer", buffer);
      });

      // this.jessibuca.on("stats", (stats) => {
      //   console.log("stats", stats);
      // });

      this.jessibuca.on("kBps", (kBps) => {
        _this.kBps = Math.round(kBps);
      });

      // 显示时间戳 PTS
      this.jessibuca.on("videoFrame", () => {});

      //
      this.jessibuca.on("metadata", () => {});
    },
    playBtnClick() {
      this.fullscreen = this.isFullscreen();
      this.play(this.videoUrl);
    },
    play(url) {
      url = this.urlAddToken(url);
      if (!url || url.length == 0) {
        this.$message.warning("当前摄像头出错，请稍后重试");
        this.pause();
        return;
      }
      if (this.jessibuca) {
        this.destroy();
      }
      this.create();
      this.jessibuca.on("play", () => {
        this.playing = true;
        // this.loaded = true
        // this.quieting = this.jessibuca.quieting
      });
      if (this.jessibuca.hasLoaded()) {
        this.jessibuca.play(url);
      } else {
        this.jessibuca.on("load", () => {
          console.log("load 播放");
          this.jessibuca.play(url);
        });
      }
    },
    pause() {
      if (this.jessibuca) {
        this.jessibuca.pause();
      }
      this.playing = false;
      this.err = "";
      this.performance = "";
    },
    destroy() {
      if (this.jessibuca) {
        this.jessibuca.destroy();
      }
      // if (document.getElementById("buttonsBox" + this.index) == null) {
      //   document.getElementById("container" + this.index).appendChild(this.btnDom)
      // }
      this.jessibuca = null;
      this.playing = false;
      this.err = "";
      this.performance = "";
    },
    showbtn() {
      if (document.getElementById("buttonsBox" + this.index) == null) {
        document
          .getElementById("container" + this.index)
          .appendChild(this.btnDom);
      }
    },
    eventcallbacK(type, message) {
      // console.log("player 事件回调")
      console.log(type);
      console.log(message);
    },
    fullscreenSwich() {
      const isFull = this.isFullscreen();
      this.jessibuca.setFullscreen(!isFull);
      this.fullscreen = !isFull;
    },
    isFullscreen() {
      return (
        document.fullscreenElement ||
        document.msFullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        false
      );
    },
  },
};
</script>

<style>
.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-play:before {
  content: "\e603";
}

.icon-pause:before {
  content: "\e6c6";
}

.icon-stop:before {
  content: "\e6a8";
}

.icon-audio-high:before {
  content: "\e793";
}

.icon-audio-mute:before {
  content: "\e792";
}

.icon-shuaxin11:before {
  content: "\e720";
}

.icon-weibiaoti10:before {
  content: "\e78f";
}

.icon-weibiaoti11:before {
  content: "\e790";
}

.icon-camera1196054easyiconnet:before {
  content: "\e791";
}

.buttons-box {
  width: 100%;
  height: 0px;
  transition: 0.4s;
  background-color: rgba(43, 51, 63, 0.7);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  user-select: none;
  z-index: 100;
  display: flex;
  align-items: center;
  overflow: hidden;
}
#jessibuca:hover .buttons-box {
  height: 40px;
}

.buttons-box-left {
  display: flex;
  flex: 1;
  align-items: center;
}
.jessibuca-btn {
  color: rgb(255, 255, 255);
  line-height: 27px;
  margin: 0px 10px;
  padding: 0px 2px;
  cursor: pointer;
  text-align: center;
  font-size: 20px !important;
}
.buttons-box-right {
  height: 100%;
  position: absolute;
  right: 0;
  display: flex;
  flex: 1;
  align-items: center;
  font-size: 20px;
}
.btn-image {
  width: 100%;
  /* height: 100%; */
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99 !important;
}
.btn-image-center {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
}
</style>
