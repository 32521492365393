var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    {
      ref: "select",
      staticStyle: { width: "100%" },
      attrs: {
        clearable: _vm.clearable,
        filterable: "",
        placeholder: _vm.$t("commons.selectTenant"),
        size: _vm.size,
        multiple: _vm.multiple,
        "multiple-limit": _vm.sizelimit,
      },
      on: {
        change: _vm.changeEvent,
        "hook:mounted": _vm.cancalReadOnly,
        "visible-change": _vm.cancalReadOnly,
        focus: _vm.cancalReadOnly,
      },
      model: {
        value: _vm.selectedValue,
        callback: function ($$v) {
          _vm.selectedValue = $$v
        },
        expression: "selectedValue",
      },
    },
    _vm._l(_vm.tenantList, function (item) {
      return _c("el-option", {
        key: item.tenantId,
        attrs: { value: item.tenantId, label: item.tenantName },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }