<template>
  <div>
    <el-upload
      ref="upload"
      :action="uploadUrl"
      :file-list="fileList"
      :on-success="handlerSuccess"
      :on-error="handlerFail"
      :with-credentials="true"
      :auto-upload="false"
    >
      <el-button slot="trigger" size="small" type="primary">{{
        $t("commons.chooseFile")
      }}</el-button>
      <el-button
        style="margin-left: 10px"
        size="small"
        type="success"
        @click="submitUpload"
        >{{ $t("commons.uploadToBg") }}</el-button
      >
    </el-upload>
  </div>
</template>

<script>
import { envInfo } from "@/constants/envInfo";

export default {
  data() {
    return {
      fileList: [],
    };
  },
  props: {
    ulType: {
      //上传类型
      type: String,
      required: false,
    },
    taskType: {
      //文件任务类型
      type: String,
      required: false,
    },
    rootPath: {
      //导入时指定为rootPath
      type: String,
      required: true,
    },
    excelType: {
      //上传excel类型
      type: String,
      required: false,
    },
  },
  computed: {
    uploadUrl() {
      let rootPath;
      let uploadRoot;
      if (this.excelType) {
        rootPath = this.rootPath;
        uploadRoot = "/param/excel/upload?ulType=";
      } else {
        uploadRoot = "/param/archive/upload?ulType=";
        rootPath = this.rootPath ? this.rootPath : envInfo.bgApp.archivePath;
      }
      let url =
        rootPath + uploadRoot + (this.ulType ? this.ulType : "DefaultUpload");
      url = this.excelType ? url + "&excelType=" + this.excelType : url;
      url = this.taskType ? url + "&taskType=" + this.taskType : url;
      return url;
    },
  },
  methods: {
    submitUpload() {
      this.$refs.upload.submit();
    },
    handlerSuccess(response) {
      this.fileList = [];
      this.$emit("success", response); //触发父组件钩子
    },
    handlerFail(err) {
      this.$emit("fail", err);
    },
  },
};
</script>
