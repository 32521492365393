<template>
  <el-select
    style="width: 100%"
    ref="select"
    @change="changeEvent"
    @hook:mounted="cancalReadOnly"
    @visible-change="cancalReadOnly"
    @focus="cancalReadOnly"
    :clearable="clearable"
    filterable
    :placeholder="$t('commons.selectTenant')"
    v-model="selectedValue"
    :size="size"
    :multiple="multiple"
    :multiple-limit="sizelimit"
  >
    <el-option
      v-for="item in tenantList"
      :value="item.tenantId"
      :key="item.tenantId"
      :label="item.tenantName"
    >
    </el-option>
  </el-select>
</template>

<script>
import { envInfo } from "@/constants/envInfo";
import request from "@/utils/request";

export default {
  name: "TenantSelect",
  data() {
    return {
      selectedValue: null,
      tenantList: [],
      call: false,
    };
  },
  props: {
    clearable: {
      type: Boolean,
      default() {
        return true;
      },
    },
    multiple: {
      //是否能够多选
      type: Boolean,
      default: false,
      required: false,
    },
    sizelimit: {
      //最多能选多少
      type: Number,
      default: 0,
      required: false,
    },
    tenantLimit: {
      //是否以当前租户列表限制
      type: Boolean,
      default: false,
      required: false,
    },
    value: {
      required: false,
    },
    model: {
      default: "1", //1:所有租户，2:排除管理员，3:排除自己(未实现，还没用到)
      required: false,
    },
    size: {
      default: "medium",
      required: false,
    },
  },
  watch: {
    value: {
      handler(val) {
        if (!this.call) {
          this.selectedValue = val ? val : [];
        }
        this.call = false;
      },
      immediate: true,
    },
  },
  created() {
    this.getTenants();
    if (this.model == "1" && !this.value) {
      this.selectedValue = -1;
    }
  },
  methods: {
    cancalReadOnly(onOff) {
      console.log(11111);
      this.$nextTick(() => {
        if (!onOff) {
          const { select } = this.$refs;
          const input = select.$el.querySelector(".el-input__inner");
          input.removeAttribute("readonly");
        }
      });
    },
    getTenants() {
      let url = envInfo.bgApp.tenantPath + "/manager/findTenantList";
      if (this.tenantLimit) {
        url = envInfo.bgApp.tenantPath + "/manager/findCurrentTenantList";
      }
      request({
        url: url,
        method: "get",
      }).then((res) => {
        if (res) {
          if (this.model == 2) {
            res = res.filter((x) => x.tenantId != -1);
          }
          this.tenantList = res;
        }
      });
    },
    selected(newVal) {
      this.call = true;
      let selectedValue = [];
      if (newVal) {
        selectedValue = newVal;
      }
      this.$emit("input", selectedValue);
    },
    changeEvent(selected) {
      console.log(22222);
      this.selected(selected);
      this.$emit("change", selected);
    },
  },
};
</script>
