<template>
<div class="pagination-container">
    <div v-if="value.total>0">
    	<el-pagination 
	    	:background="background"
	        @size-change="handleSizeChange"
	        @current-change="handleCurrentChange"
	        :current-page.sync="value.current"
	        :page-sizes="[10, 20, 30, 40]"
	        :pager-count="value.pagerCount"
	        :page-size="value.rowCount"
	        :layout="layoutOptions()"
	        :total="Number(value.total)">
	    </el-pagination>
    </div>
</div>
</template>

<script>
export default {
    props:{
        value:{ //分页数据
            type:Object,
            default:()=>{
                return {
                	current:1,//当前页
	            	rowCount:10,//每页数
		            total:0,//列表总数
                    pagerCount:7,//最大页码按钮数
                }
            },
        },
        background: {
	      type: Boolean,
	      default: false
	    },
    },
    methods:{
    	layoutOptions(){
    		let res='total,sizes, prev, pager, next,jumper';
    		if(this.value.minSize) res='total, prev, pager, next';
    		return res;
    	},
        /**
         * 改变每页显示多少条
         * @param {*number 返回每页条数} val 
         */
        handleSizeChange(val){
            this.value.current = 1;
            this.value.toPage = 1;
            this.value.rowCount = val;
            this.$emit('handleSizeChange',val);
            this.$emit('initTable');
        },
        /**
         * 改变页数
         * @param {*number 返回页数} val 
         */
        handleCurrentChange(val){
            this.value.current = val;
            this.$emit('returnBack',this.value);
            this.$emit('handleCurrentChange',val);
            this.$emit('initTable');
        },
        /**
         * 跳转至指定页数
         */
        jumpTotablePage() {
            if(this.value.current == this.value.toPage){
                return
            }
            this.value.current = this.value.toPage * 1;
            this.$emit('jumpTotablePage',this.value.current);
            this.$emit('initTable');
        },
  }
};
</script>

<style lang="scss">
    .pagination-container {
	  background: #fff;
	  padding: 18px 0px;
	 .el-pagination__sizes,.el-pagination__total {
    float: right;
    margin-right: 0;
  }
}

</style>
