<!--表格封装组件-->
<template>
    <el-table
    :data="tableData"
    ref="myTable"
    class='TTable'
    :row-key="rowKey"
    :max-height='maxHeight?maxHeight:"640px"'
    :tree-props="treeProps"
    :header-cell-class-name="cellClass"
    @selection-change="selectionChange"
    @row-click='rowClick'
    @select-all="selectAll"
    @select="select"
    @cell-click="cellClick"
    :border="border"
    :stripe='stripe'
    style="width: 100%"
    :highlight-current-row='true'
    v-loading="tbloading"
    v-bind="$attrs"
    >
        <el-table-column v-if="checkBox" type="selection" :selectable='isAllowCheck'></el-table-column>
        <el-table-column v-if="serialNumber" type="index" width="50" :label="$t('commons.index')" :fixed="serialFixed">
        	<template slot-scope="scope" >
        		<span >{{scope.$index+1}}</span>
        		<!--<span v-if='tbParams.current>0'>{{pageLimit+scope.$index+1}}</span>
        		<span v-else>{{scope.$index+1}}</span>-->
    		</template>
        </el-table-column>
        <template v-for="(it,dex) in tableTitle">
            <!-- 有头部插槽的情况 start -->
            <el-table-column
            v-if="it.slotHeader"
            :prop="it.prop"
            :label="it.label?$t(it.label):it.name"
            :show-overflow-tooltip="it.showOverflowTooltip ? it.showOverflowTooltip : true"
            :width="it.width ? it.width : ''"
            :align="it.align ? it.align : ''"
            :sortable="it.sortable ? it.sortable : false"
            :key="dex">
            <slot :name="it.slotHeaderName" slot="header" slot-scope="scope" :column="scope.column" :$index="scope.$index"></slot>
            <slot v-if="it.slotName" :name="it.slotName" slot-scope="scope" :row="scope.row" :column="scope.column" :$index="scope.$index"></slot>
            </el-table-column>
            <!-- 有头部插槽的情况 end -->
            <!-- 有插槽的情况 start -->
            <el-table-column
            v-else-if="it.slotName && !it.slotHeader"
            :label="it.label?$t(it.label):it.name"
            :fixed="it.fixed ? it.fixed : false"
            :show-overflow-tooltip="it.showOverflowTooltip ? it.showOverflowTooltip : true"
            :width="it.width ? it.width : ''"
            :align="it.align ? it.align : alignType"
            :sortable="it.sortable ? it.sortable : false"
            :key="dex">
            <slot :name="it.slotName" slot-scope="scope" :row="scope.row" :column="scope.column" :$index="scope.$index"></slot>
            </el-table-column>
            <!-- 有插槽的情况 end -->
            <!-- 没有插槽的情况 start -->
            <el-table-column
            v-else
            :prop="it.prop"
            :label="it.label?$t(it.label):it.name"
            :show-overflow-tooltip="it.showOverflowTooltip ? it.showOverflowTooltip : true"
            :width="it.width ? it.width : ''"
            :fixed="it.fixed ? it.fixed : false"
            :align="it.align ? it.align : alignType"
            :sortable="it.sortable ? it.sortable : false"
            :key="dex">
        		<template v-if="!it.childs" slot-scope="scope">
	              	<span :class='it.diyClass'>{{ scope.row[it.prop] || '-' }}</span>
            </template>
	            
	            <!--<TColumns :datas='it.childs'></TColumns>-->
        		<el-table-column v-for='(l2,i2) in it.childs' :key='i2'  :width="l2.width? l2.width : 170"
        			:prop="l2.prop" 
        			:align="l2.align ? l2.align : alignType"
        			:label="l2.label?$t(l2.label):l2.name"
        			show-overflow-tooltip
        			:class='l2.diyClass'
        			>
        			<template slot-scope="s">
	              	<span :class='l2.diyClass?l2.diyClass+s.row[l2.prop]:""'>{{ s.row[l2.prop] || '-' }}</span>
            </template>
        		</el-table-column>
            </el-table-column>
            <!-- 没有插槽的情况 end -->

        </template>
    </el-table>
</template>
<script>
import TColumns from './TColumns.vue';
export default {
	components: {TColumns},
    props:{
        checkBox:{   //是否启用勾选框
            type:Boolean,
            default:false
        },
        disCkecks:{ //不允许勾选
            type:Array,
            default:()=>{
                return []
            },
        },
        serialNumber:{   //是否启用序号
            type:Boolean,
            default:false
        },
        serialFixed:{   //是否启用序号
            type:Boolean|String,
            default:false
        },
        tableData:{ //表格数据
            type:Array,
            default:()=>{
                return []
            },
        },
        tbParams:{
        	type:Object,
        	default:()=>{
                return {
                	current:0,//当前页
                	rowCount:0,//每页数
		            total:0,//列表总数
		            totalPage: 0
                }
            },
        },
        tableTitle:{ //表头数据
            type:Array,
            default:()=>{
                return []
            },
        },
        maxHeight:{
        	type:Number,
        	default:0,
        },
        rowKey:{//使用树形结构必填
            type:String,
            default:'',
        },
        alignType:{//使用树形结构必填
            type:String,
            default:'left',
        },
        treeProps:{//使用树形结构表格
            type:Object,
            default:()=>{
                return { hasChildren: 'hasChildren', children: 'children' }
            },
        },
        notableRowClick:{//取消内置的当行点击选中
            type:Boolean,
            default:false
        },
        border:{//是否要边框  默认要
            type:Boolean,
            default:true
        },
        stripe:{
            type:Boolean,
            default:true
        },
        tbloading:{
        	type:Boolean,//是否启用loading
            default:false
        }, 
    },
    watch:{
		tableData:{
			deep: true,  // 深度监听
			handler(newval){
				this.$nextTick(()=>{
					this.pageLimit=(this.tbParams.current-1) * this.tbParams.rowCount;
					
				})
			}
		},
	},
    data(){
        return {
            selection:[],//选中项
            pageLimit:0,
        }
    },
    methods:{
    	langNamekey(keys){
    		return this.$t(keys)
    	},
    	isAllowCheck(row,index){
    		if(this.disCkecks.length<1){
    			return true
    		}else{
    			let res=this.disCkecks.filter(it=>{return it.id==row.id})
    			if(res.length>0){
    				return false
    			}else{
    				return true
    			}
    		}
    	},
        selectionChange(selection){//选中项发生变化时
            this.selection = selection;
            this.$emit('selection-change',selection)
        },
        rowClick(row, column, event){//当某一行被点击时会触发该事件
            if(!this.notableRowClick){
                if(this.disCkecks.length<1){
                	this.$refs.myTable.toggleRowSelection(row);
                }
            }
            this.$emit('row-click',row, column, event)
        },
        toggleRowSelection(row, selected){//用于多选表格，切换某一行的选中状态，如果使用了第二个参数，则是设置这一行选中与否（selected 为 true 则选中）
            this.$refs.myTable.toggleRowSelection(row,selected);
        },
        toggleRowExpansion(row, expanded){
            this.$refs.myTable.toggleRowExpansion(row, expanded);
        },
        selectAll(selection){//当用户手动勾选全选 Checkbox 时触发的事件
            this.$emit('select-all',selection)
        },
        select(selection, row){//当用户手动勾选数据行的 Checkbox 时触发的事件
            this.$emit('select',selection, row)
        },
        cellClick(row, column, cell, event){//当某个单元格被点击时会触发该事件
            this.$emit('cell-click', row, column, cell, event)
        },
        cellClass(row){
            if (row.columnIndex === 0) {
                return 'disabledCheck'
            }
        },
    }
}
</script>
<style lang="scss">
	.TTable{
		.el-tooltip__popper {
		  max-width: 30%;
		}
		.el-button.el-button--text{
			/*padding: 0;*/
		}
		.el-table__header tr th{
			background: #f4f7f9 !important;
		}
	 	th.el-table__cell > .cell {
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      ::-webkit-scrollbar {
			  width: 10px;
			  height: 10px;
			}
	}


</style>
