<template>
    <div class="map">
        <el-dialog :close-on-click-modal="false" width="80%" top="6vh" :visible.sync="mapSelectDialog" :before-close="closeDialog">
            <template slot="title">
                <title-icon/>{{ $t('commons.location') }}
            </template>
            <baidu-map :zoom="zoom" class="map" :center="center" @click="handlerClick" @ready="handler" :scroll-wheel-zoom="true">
                <!--定位控件-->
                <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :autoLocation="true"></bm-geolocation>
                <!--覆盖物标记控件-->
                <template v-if="position != null">
                    <bm-marker :position="position" :icon="icon" @dragend="dragendMap" :dragging="true" animation="BMAP_ANIMATION_BOUNCE">
                    </bm-marker>
                </template>
                <!--城市列表控件-->
                <bm-city-list anchor="BMAP_ANCHOR_TOP_LEFT"></bm-city-list>
            </baidu-map>
            <div slot="footer">
                <el-button v-if="actions" type="primary" @click="saveMapSelect">{{$t('commons.save')}}</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name:'MapSelect',
        props: {
            position: {
                type: Object,
                required: false
            },
            cityName: {
                type: String,
                required: false
            },
            mapSelectDialog:{
                type: Boolean,
                required: true
            },
            actions:{
                type: Boolean,
                required: true
            }
        },
        computed: {
            getMapSelectDialog() {
                return this.mapSelectDialog;
            },
        },
        watch: {
            //侦听地图选择窗口变化
            getMapSelectDialog() {
                this.mapSelectDialogChange();
            },
        },
        data() {
            return {
                zoom: 3,
                center:{lng: 0, lat: 0},
                icon: {
                    url: 'https://api.map.baidu.com/images/marker_red_sprite.png',
                    size: {width: 52, height: 26},
                    opts: {anchor: {width: 27, height:13}}
                },
                citys:[],
            };
        },
        methods: {
            mapSelectDialogChange(){
                if(this.mapSelectDialog){
                    if(this.position){
                        this.center = this.position;
                    }else{
                        this.center = this.cityName;
                        this.position = null;
                    }
                    this.zoom = 15
                }
            },
            handler ({BMap, map}) {
                console.log(BMap, map)
                if(this.position){
                    this.center = this.position;
                }else{
                    this.center = this.cityName;
                    this.position = null;
                }
                this.zoom = 15
            },
            setPosition (point) {
                this.$parent.position = point;
                this.$emit('checkPoint',point)
            },
            handlerClick({point}) {
                if(this.actions){
                    this.setPosition(point)
                }
            },
            dragendMap({point}){
                this.position = point;
            },

            saveMapSelect(){
                if(this.position){
                    this.$parent.selectMapPosition(this.position);
                }else{
                    this.$message({
                        message: "请选择地图位置！",
                        type:'info'
                    })
                }
            },
            closeDialog(){
                this.$parent.mapSelectDialog = false;
            }
        }
    }
</script>

<style scoped>

    .map {
        height: 70vh;
    }
</style>
