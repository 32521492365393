var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    {
      ref: "select",
      staticStyle: { width: "100%" },
      attrs: {
        filterable: "",
        clearable: "",
        size: _vm.size,
        "remote-method": _vm.filterAccounts,
        multiple: _vm.multiple,
        "multiple-limit": _vm.limit,
        "value-key": _vm.valueField,
        remote: "",
        loading: _vm.loading,
        placeholder: _vm.$t("commons.searchAccount"),
      },
      on: {
        "hook:mounted": _vm.cancalReadOnly,
        "visible-change": _vm.cancalReadOnly,
        change: _vm.changeAccount,
      },
      model: {
        value: _vm.selectedValues,
        callback: function ($$v) {
          _vm.selectedValues = $$v
        },
        expression: "selectedValues",
      },
    },
    _vm._l(_vm.accountList, function (item) {
      return _c(
        "el-option",
        {
          key: item.userAccount,
          attrs: {
            label: item.userName ? item.userName : item.userAccount,
            value: item,
          },
        },
        [
          _c("span", { staticStyle: { float: "left" } }, [
            _vm._v(_vm._s(item.userName ? item.userName : item.userAccount)),
          ]),
          _c(
            "span",
            {
              staticStyle: {
                float: "right",
                color: "#8492a6",
                "font-size": "13px",
              },
            },
            [_vm._v(_vm._s(item.userAccount))]
          ),
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }