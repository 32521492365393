var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          ref: "upload",
          attrs: {
            action: _vm.uploadUrl,
            "file-list": _vm.fileList,
            "on-success": _vm.handlerSuccess,
            "on-error": _vm.handlerFail,
            "with-credentials": true,
            "auto-upload": false,
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: { slot: "trigger", size: "small", type: "primary" },
              slot: "trigger",
            },
            [_vm._v(_vm._s(_vm.$t("commons.chooseFile")))]
          ),
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "10px" },
              attrs: { size: "small", type: "success" },
              on: { click: _vm.submitUpload },
            },
            [_vm._v(_vm._s(_vm.$t("commons.uploadToBg")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }