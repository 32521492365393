<template>
  <div class="water-energy-search">
    <el-select v-model="year" placeholder="请选择年">
      <el-option
        v-for="item in yearOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
    <el-select
      v-model="month"
      clearable
      style="margin-left: 20px"
      placeholder="请选择月"
    >
      <el-option
        v-for="(item, index) in Array(12)"
        :disabled="index >= new Date().getMonth() + 1"
        :key="index + 1"
        :label="`${index + 1}月`"
        :value="index + 1"
      >
      </el-option>
    </el-select>
    <el-select
      v-if="type !== 'rwork'"
      v-model="date"
      :disabled="month.length !== 1"
      multiple
      collapse-tags
      clearable
      style="margin-left: 20px"
      placeholder="请选择日"
    >
      <el-option
        v-for="item in datesOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
    <el-select
      v-if="type === 'rwork'"
      v-model="project"
      clearable
      style="margin-left: 20px"
      placeholder="请选择项目"
      @change="projectChange"
    >
      <el-option
        v-for="item in projectList"
        :key="item.projectId"
        :label="item.projectName"
        :value="item.projectId"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { findCompanyProjectList } from "@/api/business/order/tenant/order";
export default {
  name: "waterEnergySearch",
  props: {
    type: {
      type: String,
      default() {
        return "";
      },
    },
  },
  watch: {
    month: {
      handler(month) {
        this.daysDeal(month);
      },
    },
  },
  data() {
    return {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      date: [],
      subProject: "",
      project: "",
      subProjectList: [],
      projectList: [],
      yearOptions: [
        {
          value: 2023,
          label: "2023",
        },
      ],
      datesOptions: [],
    };
  },
  created() {
    if (this.type === "rwork") {
      this.initRworkDatas();
    }
  },
  methods: {
    initRworkDatas() {
      this.projectList = [];
      findCompanyProjectList().then((res) => {
        res.forEach((item) => {
          item.projectChildren.forEach((subItem) => {
            this.projectList.push(subItem);
          });
        });
        this.project = this.projectList[0].projectId;
        this.projectChange();
      });
    },
    projectChange() {
      const { year, month, project } = this;
      this.$emit("searchParamChange", {
        project,
        year,
        month,
      });
    },
    daysDeal(month) {
      console.log("month", month);
      if (!month || month.length !== 1) {
        this.date = [];
      } else {
        const currentDates = this.getDays(`${this.year}-${month}`);
        this.datesOptions = currentDates.allDay.map((item) => {
          return {
            value: item,
            label: item,
          };
        });
      }
    },
    // currentMonth: 2022-11
    getDays(currentMonth) {
      var last = this.showLastDay(currentMonth);
      var start = this.showFirstDay(currentMonth);
      var date = new Date(currentMonth);
      var a = new Array();
      for (var i = start; i <= last; i++) {
        // a[i - 1] = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + i;
        a[i - 1] = i < 10 ? `0${i}` : i;
      }
      var res = {
        firstDay: last,
        lastDay: start,
        allDay: a,
      };
      return res;
    },
    //月分第一天
    showFirstDay(currentMonth) {
      var Nowdate = new Date(currentMonth);
      var MonthFirstDay = new Date(
        Nowdate.getYear(),
        Nowdate.getMonth(),
        1
      ).getDate();
      return MonthFirstDay;
    },
    //月份最后一天
    showLastDay(currentMonth) {
      var Nowdate = new Date(currentMonth);
      var tmpDate = new Date(Nowdate.getYear(), Nowdate.getMonth() + 1, 1);
      var MonthLastDay = new Date(tmpDate - 86400000).getDate();
      return MonthLastDay;
    },
  },
};
</script>

<style lang="less" scoped>
.water-energy-search {
}
</style>