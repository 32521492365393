<!--表格封装组件  查询条件在表头里-->
<template>
	<div class="YTb2">
		
    <el-table
    :data="tableArrs"
    ref="myTable2"
    class='TTable2'
    :class='{isBoder:border}'
    :row-key="rowKey"
    :max-height='maxHeight?maxHeight:"640px"'
    :tree-props="treeProps"
    :header-cell-class-name="cellClass"
    @selection-change="selectionChange"
    @row-click='rowClick'
    @select-all="selectAll"
    @select="select"
    @cell-click="cellClick"
    default-expand-all
    fit
    :border="border"
    style="width: 100%"
    :highlight-current-row='true'
    v-loading="tbloading"
    v-bind="$attrs"
    >
        <el-table-column v-if="checkBox" type="selection" :width="checkWidth" :selectable='isAllowCheck'></el-table-column>
        <el-table-column v-if="serialNumber" type="index" width="50" :label="$t('commons.index')" :fixed="serialFixed">
        	<template slot-scope="scope" >
        		<span v-if="showQuery"><span v-if="scope.$index>0">{{scope.$index}}</span></span>
        		<span v-else>{{scope.$index}}</span>
    		</template>
        </el-table-column>
        <template v-for="(it,dex) in tableTitle">
            <!-- 有头部插槽的情况 start -->
            <el-table-column
            v-if="it.slotHeader"
            :prop="it.prop"
            :label="it.label?$t(it.label):it.name"
            :show-overflow-tooltip="it.showOverflowTooltip ? it.showOverflowTooltip : true"
            :width="it.width ? it.width : ''"
            :align="it.align ? it.align : ''"
            :sortable="it.sortable ? it.sortable : false"
            :key="dex">
            <slot :name="it.slotHeaderName" slot="header" slot-scope="scope" :column="scope.column" :$index="scope.$index"></slot>
            <slot v-if="it.slotName" :name="it.slotName" slot-scope="scope" :row="scope.row" :column="scope.column" :$index="scope.$index"></slot>
            </el-table-column>
            <!-- 有头部插槽的情况 end -->
            <!-- 没有插槽的情况 start -->
            <el-table-column
            v-else
            :prop="it.prop"
            :label="it.label?$t(it.label):it.name"
            :show-overflow-tooltip="it.showOverflowTooltip ? it.showOverflowTooltip : true"
            :width="it.width ? it.width : ''"
            :fixed="it.fixed ? it.fixed : false"
            :align="it.align ? it.align : alignType"
            :sortable="it.sortable ? it.sortable : false"
            :key="dex">
	            <template slot-scope="scope">
	            	<template >
	            		<span v-if="scope.$index<1" class="final-search-line">
	            			<div v-if='moreFroms.length>0&showQuery&&it.slotName=="operating"'>
			              	  	<el-button @click.stop='openDrawer=true'>更多</el-button>
			              	</div>
			              	<slot v-if="it.querySlotName" :name="it.querySlotName"></slot>
			              	<div v-else-if="it.queryInfo">
			              	  	<el-input class='max240' v-if='elInput.indexOf(it.queryInfo.type) != -1' 
			              	  		@keyup.native="eventHandlerDebounce" clearable 
			              	  		@clear='filterChange'
			              	  		v-model="it.queryInfo.value"  :placeholder="it.queryInfo.placeholder ? it.queryInfo.placeholder : '请输入'"/>
			              		<div v-else-if="elSelect.indexOf(it.queryInfo.type) != -1" @change="filterChange">
			              			<el-select
			                         	:multiple="it.queryInfo.multiple ? it.queryInfo.multiple : false"
			                         	:multiple-limit="it.queryInfo.multipleLimit ? it.queryInfo.multipleLimit : 0"
			                         	v-model="it.queryInfo.value"
			                         	@change="filterChange(it)"
			                         	:clearable='!it.queryInfo.notClear'
			                         	filterable
			                         	:disabled="(disabled || it.queryInfo.disabled) ? true : false"
			                         	:readonly="(readonly || it.queryInfo.readonly) ? true : false" 
			                         	:placeholder="it.queryInfo.placeholder ? it.queryInfo.placeholder : '请选择'">
			                            	<el-option :label="it.labelName ? item[it.labelName] : item.label" :value="it.valueName ? item[it.valueName] : item.value" v-for="(item,dex2) in it.queryInfo.options" :key="dex2"></el-option>
			                        </el-select>
			              		</div>
			              		
			              		<div v-else-if="elDataPicker.indexOf(it.queryInfo.type) != -1">
			              			<el-date-picker style='width: 100%;'
		                            :is-range="it.queryInfo.isRange"
		                            :type="it.queryInfo.type"
		                            :clearable='!it.queryInfo.notClear'
		                            v-model="it.queryInfo.value"
		                            :picker-options="it.queryInfo.pickerOptions ? it.queryInfo.pickerOptions : {}"
		                            :disabled="(disabled || it.queryInfo.disabled) ? true : false"
		                            :readonly="(readonly || it.queryInfo.readonly) ? true : false"
		                            range-separator="-"
		                            start-placeholder="开始时间"
		                            end-placeholder="结束时间"
		                            :value-format="it.queryInfo.valueFormat"
		                            :default-time="it.queryInfo.defaultTime ? it.queryInfo.defaultTime : ''"
		                            @change="filterChange(it)"
		                            :placeholder="it.queryInfo.placeholder ? it.queryInfo.placeholder : '请输入'+it.name">
		                        </el-date-picker>
			              			
			              		</div>
			              		<div v-else-if="eltimePicker.indexOf(it.queryInfo.type) != -1">
				              		<el-time-picker
				              			style='width: 100%;'
			                            :is-range="it.queryInfo.isRange ? it.queryInfo.isRange : false"
			                            v-model="it.queryInfo.value"
			                            :picker-options="it.queryInfo.pickerOptions ? it.queryInfo.pickerOptions : {}"
			                            :disabled="(disabled || it.queryInfo.disabled) ? true : false"
			                            :readonly="(readonly || it.queryInfo.readonly) ? true : false"
			                            range-separator="至"
			                            start-placeholder="开始时间"
			                            end-placeholder="结束时间"
			                            :value-format="it.queryInfo.valueFormat"
			                            @change="filterChange(it)"
			                            :placeholder="it.queryInfo.placeholder ? it.queryInfo.placeholder : '请输入'+it.name">
			                        </el-time-picker>
		                        </div>
		                        <div v-else-if="elCascader.indexOf(it.queryInfo.type) != -1">
				              		<el-cascader  
				              			v-model="it.queryInfo.value" 
				              			:disabled="(disabled || it.queryInfo.disabled) ? true : false"
				              			@change='filterChange(it)' 
				              			:options="it.queryInfo.options" 
				              			:props="it.queryInfo.checkProps?it.queryInfo.checkProps:checkProps"  style='width: 100%;'></el-cascader>
                       
		                        </div>
		                        
		                        <div v-else-if="it.queryInfo.type=='employe'">
		                        	<el-cascader 
		                        		v-model="it.queryInfo.value" 
	                        		 	:show-all-levels='false' 
	                        		 	:disabled="(disabled || it.queryInfo.disabled) ? true : false"
	                        		  	filterable @change='filterChange(it)' 
	                        		  	:options="$store.state.app.employeeArrs" 
	                        		  	:props="it.queryInfo.checkProps?it.queryInfo.checkProps:empProps" 
	                        		   	style='width: 100%;' :collapse-tags='true' >
		                            	<template slot-scope="{ node, data }">
										    <span v-if="!node.isLeaf">
										    	{{ data.deptName?data.deptName:data.companyName }}
										    </span>
										    <span v-else>
										    	{{ data.userName }}
										    </span>
									  	</template>
		                            </el-cascader>
                       
		                        </div>
		                        
		                        
		                        
			              	</div>
			              	
			            </span>
			            <span v-else>
           					<slot v-if="it.slotName" :name="it.slotName" :row="scope.row" :column="scope.column" :$index="scope.$index"></slot>
           					<span v-else :class='it.diyClass'>{{ scope.row[it.prop] || '-' }}</span>
			            </span>
	            		
	            	</template>
	            	<!--<template v-else>
	            		<slot v-if="it.slotName" :name="it.slotName"></slot>
       					<span v-else :class='it.diyClass'>{{ scope.row[it.prop] || '-' }}</span>
	            	</template>-->
		          </template>


            </el-table-column>
            <!-- 没有插槽的情况 end -->

        </template>
    </el-table>
    <div v-if="!tableArrs||tableArrs.length<2" style="text-align: center;color: #ccc;padding: 12px;"  :class='{nullBorder:nullIcon}'> 
    	<div v-if="nullIcon" ><img src="@/assets/images/tbnull.png" :style="{width:nullIcon+'px'}" /></div>
    	暂无数据
    </div>
	<el-drawer title="高级筛选" :visible.sync="openDrawer" direction="rtl" >
		<div class="mfWarp">
			<div class="fbody">
				<TForm  ref="htmlFrom" :lableRow='morelableRow' :autoCheck='true' labelPosition='left' :model="searchObjs" :formlist="moreFroms" label-width='88px' :rowGutter='16'>
	                <template  v-for="(slo,s2) in moreFroms" v-if="slo.type=='slot'" :slot="slo.slotName" slot-scope="scope">
	                   <slot :name="slo.slotName" ></slot>
	                </template>
				</TForm>
			</div>
			<div class="btms">
			 	<el-button @click.stop='clearMoreFiler'>清空</el-button>
  				<el-button type="primary" @click.stop='toMoreFiler'>筛选</el-button>
			</div>
		</div>
	</el-drawer>
		
	</div>
</template>
<script>
import TForm from '@/components/YTable/TForm.vue';
import { debounce } from "lodash";
import TColumns from './TColumns.vue';
export default {
	components: {TForm,TColumns},
    props:{
    	showQuery:{   //是否查询条件
            type:Boolean,
            default:false
        },
        treeCkBox:{   //是否更多查询按钮
            type:Boolean,
            default:false
        },
        isMore:{   //是否更多查询按钮
            type:Boolean,
            default:true
        },
        treeCkBox:{   //是否更多查询按钮
            type:Boolean,
            default:false
        },
        morelableRow:{   //更多查询的item是否换行
            type:Boolean,
            default:false
        },
        moreFroms:{ //更多查询条件
            type:Array,
            default:()=>{
                return []
            },
        },
        checkBox:{   //是否启用勾选框
            type:Boolean,
            default:false
        },
        checkWidth:{
        	type:Number,
        	default:50
        },
        disCkecks:{ //不允许勾选
            type:Array,
            default:()=>{
                return [
                	{id:'-999',name:'第一行'}
                ]
            },
        },
     	disabled:{//是否禁用
            type:Boolean,
            default:false,
        },
        readonly:{//只读
            type:Boolean,
            default:false,
        },
        serialNumber:{   //是否启用序号
            type:Boolean,
            default:false
        },
        serialFixed:{   //是否启用序号
            type:Boolean|String,
            default:false
        },
        nullIcon:{   //无数据时候是否展示图片
            type:Boolean|String,
            default:false
        },
        tableData:{ //表格数据
            type:Array,
            default:()=>{
                return []
            },
        },
        tbParams:{
        	type:Object,
        	default:()=>{
                return {
                	current:0,//当前页
                	rowCount:0,//每页数
		            total:0,//列表总数
		            totalPage: 0
                }
            },
        },
        tableTitle:{ //表头数据
            type:Array,
            default:()=>{
                return []
            },
        },
        maxHeight:{
        	type:Number,
        	default:0,
        },
        rowKey:{//使用树形结构必填
            type:String,
            default:'',
        },
        alignType:{//使用树形结构必填
            type:String,
            default:'left',
        },
        treeProps:{//使用树形结构表格
            type:Object,
            default:()=>{
                return { hasChildren: 'children', children: 'children' }
            },
        },
        notableRowClick:{//取消内置的当行点击选中
            type:Boolean,
            default:false
        },
        border:{//是否要边框  默认要
            type:Boolean,
            default:true
        },
        tbloading:{
        	type:Boolean,//是否启用loading
            default:false
        }, 
        loadInit:{
        	type:Boolean,//是否加载后发起请求
            default:true
        }
    },
    computed: {},
    watch:{
		tableData:{
			deep: true,  // 深度监听
			handler(newval){
				this.$nextTick(()=>{
					this.resetTbDatas();
					this.pageLimit=(this.tbParams.current-1) * this.tbParams.rowCount;
					
				})
			}
		},
		showQuery:{
			deep: true,  // 深度监听
			handler(newval){
				this.$nextTick(()=>{
					this.resetTbDatas();
					this.searchLineSelectionToggle(newval);
				})
			}
		},
		moreFroms:{
			deep: true,  // 深度监听
			handler(newval){
				this.$nextTick(()=>{
					this.initMoreObjs();
				})
			}
			
		},
	},
    data(){
        return {
            selection:[],//选中项
            pageLimit:0,
            tableArrs:[],
            resetNum:1,
            elInput:['text','number','input'],//输入框类型
            elSelect:['select'],//选择框类型
            elCascader:['cascader'],//联级选择框类型
            elDataPicker:['date','year','month','week','dates','daterange','monthrange','datetime','datetimerange'],//日期选择器类型
            eltimePicker:['timePicker'],//时间选择器类型
            eventHandlerDebounce: debounce((e) => {
		        this.filterChange(e);
	      	}, 500),
	      	
	      	openDrawer:false,
	      	searchObjs:{},
	      	checkProps:{ multiple: true,value:'code',label:'name',children:'children',checkStrictly:true},
    		empProps:{ multiple: false,value:'userId',label:'userName',children:'children',emitPath: false,},
	      	
        }
    },
    mounted() {
    	this.initMoreObjs();
    	this.resetTbDatas();
    	this.$nextTick(()=>{
    		if(this.loadInit){
    			this.filterChange();
    		}
    	})
    },
    methods:{
    	initMoreObjs(){
    		//重置查询条件
    		this.searchObjs={};
    		let isEmp=0;
    		this.tableTitle.map(item=>{
    			const {queryInfo}=item;
    			if(queryInfo){
    				this.$set(this.searchObjs, item.prop, item.queryInfo.value);
    				if(queryInfo.type=='employe'){
    					if(isEmp<1){
    						this.$store.dispatch('getEmployeeDatas');
    						isEmp++;
    					}
    				}
    			}
    		})
    		this.moreFroms.map(item=>{
    			this.$set(this.searchObjs, item.keys, item.value);
    		})
//  		console.log('XXXX',JSON.parse(JSON.stringify(this.searchObjs)))
    	},
    	toMoreFiler(){
//  		console.log('toMoreFiler',JSON.parse(JSON.stringify(this.searchObjs)))
    		this.openDrawer=false;
    		this.$emit('rowSeach',this.searchObjs);
    	},
    	clearMoreFiler(){
    		for(let keys in this.searchObjs){
    			this.searchObjs[keys]='';
    			let item=this.searchObjs[keys];
    			if(typeof(item)=='object'){
    				if(item.length>-1){
    					this.searchObjs[keys]=[];
    				}else{
    					this.searchObjs[keys]={};
    				}
    			}
    		}
    		this.$emit('clearSlot');//考虑若有插槽的情况下还需要清空插槽的值；
    	},
    	resetTbDatas(){
    		let row1={id:'-999'};
			this.tableTitle.map(it=>{
				if(it.queryInfo){
					row1[it.prop]={...it};
				}
			})
			this.tableArrs=[row1].concat(this.tableData);
//  		if(this.showQuery){
//  			this.tableArrs=[row1].concat(this.tableData);
//  		}else{
//  			this.tableArrs=[].concat(this.tableData);
//  		}
    		
    	},
    	searchLineToolTipHidden() {
	      // 因为渲染关系，没办法去除搜索行的toolTips，所以用jq处理下
	      this.$nextTick(() => {
	        try {
	          $(".final-search-line").parent().removeClass("el-tooltip");
	        } catch (error) {
	          console.log("去除tooltips失败:", error);
	        }
	      });
	    },
	    searchLineSelectionToggle(type) {
    		// 隐藏第一个selection
		      this.$nextTick(() => {
		        try {
		          //this.$refs.myTable2.doLayout();
		          if(type){
		          	$(".hideSeach").removeClass("hideSeach");
		          }else{
		          	$(".el-table__row:first").addClass("hideSeach");
		          	$(".el-table__fixed-right tbody").find('.el-table__row:first').addClass("hideSeach");
		          }
		        } catch (error) {
		          console.log("隐藏行失败", error);
		        }
		      });
	    },
    	filterChange(){
    		let result={};
    		this.tableTitle.map(item=>{
    			const {queryInfo}=item;
    			if(queryInfo){
    				this.searchObjs[item.prop]=item.queryInfo.value;
    			}
    		})
//  		console.log('AAAA',JSON.parse(JSON.stringify(this.searchObjs)))
    		this.$emit('rowSeach',this.searchObjs)
    		
    	},
    	langNamekey(keys){
    		return this.$t(keys)
    	},
    	isAllowCheck(row,index){
    		if(this.disCkecks.length<1){
    			return true
    		}else{
    			let res=this.disCkecks.filter(it=>{return it.id==row.id})
    			if(res.length>0){
    				return false
    			}else{
    				return true
    			}
    		}
    	},
        selectionChange(selection){//选中项发生变化时
            this.selection = selection;
            this.$emit('selection-change',selection)
        },
        rowClick(row, column, event){//当某一行被点击时会触发该事件
            if(!this.notableRowClick){
                if(this.disCkecks.length<1){
                	this.$refs.myTable2.toggleRowSelection(row);
                }
            }
            this.$emit('row-click',row, column, event)
        },
        toggleRowSelection(row, selected){//用于多选表格，切换某一行的选中状态，如果使用了第二个参数，则是设置这一行选中与否（selected 为 true 则选中）
            this.$refs.myTable2.toggleRowSelection(row,selected);
        },
        toggleRowExpansion(row, expanded){
            this.$refs.myTable2.toggleRowExpansion(row, expanded);
        },
        selectAll(selection){//当用户手动勾选全选 Checkbox 时触发的事件
            this.$emit('select-all',selection)
        },
        select(selection, row){//当用户手动勾选数据行的 Checkbox 时触发的事件
            this.$emit('select',selection, row)
        },
        cellClick(row, column, cell, event){//当某个单元格被点击时会触发该事件
            this.$emit('cell-click', row, column, cell, event)
        },
        cellClass(row){
            if (row.columnIndex === 0) {
                return 'disabledCheck'
            }
        },
    }
}
</script>
<style lang="scss">
	
	
	.TTable2{
		   
		.el-tooltip__popper {
		  max-width: 30%;
		}
		.el-button.el-button--text{
			/*padding: 0;*/
			color: #1a4cec !important;
		}
		.el-table__header tr th{
			height: 56px;
			/*background: #f4f7f9 !important;*/
		}
	 	th.el-table__cell > .cell {
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      ::-webkit-scrollbar {
			  width: 10px;
			  height: 10px;
			}
		.el-table__row.hideSeach{
			display: none !important;
		}
	}
	.isBoder.TTable2{
		border: 1px solid #EBEEF5;
	}
	.max240{
		max-width: 240px;
	}
	.YTb2{
		.el-drawer__header span{
			color: #000;
			font-weight: bold;
		}
		.mfWarp{
			width: 100%;
			height: 100%;
			position: relative;
			
			padding-bottom: 60px;
			box-sizing: border-box;
			.fbody{
				height: 100%;
				overflow-y: auto;
				padding: 12px 24px;
			}
			.btms{
				position: absolute;
				bottom: 0;
				width: 100%;
				text-align: right;
				height: 48px;
				padding-top: 6px;
				    padding-right: 24px;
				border-top: 1px solid #EBEEF5;
			}
		}
		.nullBorder{
			border: 1px solid #EFEFEF;
			border-top: none;
			color: #B5BECE !important;
		}
	} 
	
</style>
