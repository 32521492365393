var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-table",
    _vm._b(
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.tbloading,
            expression: "tbloading",
          },
        ],
        ref: "myTable",
        staticClass: "TTable",
        staticStyle: { width: "100%" },
        attrs: {
          data: _vm.tableData,
          "row-key": _vm.rowKey,
          "max-height": _vm.maxHeight ? _vm.maxHeight : "640px",
          "tree-props": _vm.treeProps,
          "header-cell-class-name": _vm.cellClass,
          border: _vm.border,
          stripe: _vm.stripe,
          "highlight-current-row": true,
        },
        on: {
          "selection-change": _vm.selectionChange,
          "row-click": _vm.rowClick,
          "select-all": _vm.selectAll,
          select: _vm.select,
          "cell-click": _vm.cellClick,
        },
      },
      "el-table",
      _vm.$attrs,
      false
    ),
    [
      _vm.checkBox
        ? _c("el-table-column", {
            attrs: { type: "selection", selectable: _vm.isAllowCheck },
          })
        : _vm._e(),
      _vm.serialNumber
        ? _c("el-table-column", {
            attrs: {
              type: "index",
              width: "50",
              label: _vm.$t("commons.index"),
              fixed: _vm.serialFixed,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return [_c("span", [_vm._v(_vm._s(scope.$index + 1))])]
                  },
                },
              ],
              null,
              false,
              2966791042
            ),
          })
        : _vm._e(),
      _vm._l(_vm.tableTitle, function (it, dex) {
        return [
          it.slotHeader
            ? _c("el-table-column", {
                key: dex,
                attrs: {
                  prop: it.prop,
                  label: it.label ? _vm.$t(it.label) : it.name,
                  "show-overflow-tooltip": it.showOverflowTooltip
                    ? it.showOverflowTooltip
                    : true,
                  width: it.width ? it.width : "",
                  align: it.align ? it.align : "",
                  sortable: it.sortable ? it.sortable : false,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return _vm._t(it.slotHeaderName, null, {
                          column: scope.column,
                          $index: scope.$index,
                        })
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return it.slotName
                          ? _vm._t(it.slotName, null, {
                              row: scope.row,
                              column: scope.column,
                              $index: scope.$index,
                            })
                          : _vm._e()
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : it.slotName && !it.slotHeader
            ? _c("el-table-column", {
                key: dex,
                attrs: {
                  label: it.label ? _vm.$t(it.label) : it.name,
                  fixed: it.fixed ? it.fixed : false,
                  "show-overflow-tooltip": it.showOverflowTooltip
                    ? it.showOverflowTooltip
                    : true,
                  width: it.width ? it.width : "",
                  align: it.align ? it.align : _vm.alignType,
                  sortable: it.sortable ? it.sortable : false,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return _vm._t(it.slotName, null, {
                          row: scope.row,
                          column: scope.column,
                          $index: scope.$index,
                        })
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : _c(
                "el-table-column",
                {
                  key: dex,
                  attrs: {
                    prop: it.prop,
                    label: it.label ? _vm.$t(it.label) : it.name,
                    "show-overflow-tooltip": it.showOverflowTooltip
                      ? it.showOverflowTooltip
                      : true,
                    width: it.width ? it.width : "",
                    fixed: it.fixed ? it.fixed : false,
                    align: it.align ? it.align : _vm.alignType,
                    sortable: it.sortable ? it.sortable : false,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return !it.childs
                            ? [
                                _c("span", { class: it.diyClass }, [
                                  _vm._v(_vm._s(scope.row[it.prop] || "-")),
                                ]),
                              ]
                            : undefined
                        },
                      },
                    ],
                    null,
                    true
                  ),
                },
                _vm._l(it.childs, function (l2, i2) {
                  return _c("el-table-column", {
                    key: i2,
                    class: l2.diyClass,
                    attrs: {
                      width: l2.width ? l2.width : 170,
                      prop: l2.prop,
                      align: l2.align ? l2.align : _vm.alignType,
                      label: l2.label ? _vm.$t(l2.label) : l2.name,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (s) {
                            return [
                              _c(
                                "span",
                                {
                                  class: l2.diyClass
                                    ? l2.diyClass + s.row[l2.prop]
                                    : "",
                                },
                                [_vm._v(_vm._s(s.row[l2.prop] || "-"))]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  })
                }),
                1
              ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }