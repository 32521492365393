var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "map" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            width: "80%",
            top: "6vh",
            visible: _vm.mapSelectDialog,
            "before-close": _vm.closeDialog,
          },
          on: {
            "update:visible": function ($event) {
              _vm.mapSelectDialog = $event
            },
          },
        },
        [
          _c(
            "template",
            { slot: "title" },
            [
              _c("title-icon"),
              _vm._v(_vm._s(_vm.$t("commons.location")) + "\n        "),
            ],
            1
          ),
          _c(
            "baidu-map",
            {
              staticClass: "map",
              attrs: {
                zoom: _vm.zoom,
                center: _vm.center,
                "scroll-wheel-zoom": true,
              },
              on: { click: _vm.handlerClick, ready: _vm.handler },
            },
            [
              _c("bm-geolocation", {
                attrs: {
                  anchor: "BMAP_ANCHOR_BOTTOM_RIGHT",
                  showAddressBar: true,
                  autoLocation: true,
                },
              }),
              _vm.position != null
                ? [
                    _c("bm-marker", {
                      attrs: {
                        position: _vm.position,
                        icon: _vm.icon,
                        dragging: true,
                        animation: "BMAP_ANIMATION_BOUNCE",
                      },
                      on: { dragend: _vm.dragendMap },
                    }),
                  ]
                : _vm._e(),
              _c("bm-city-list", { attrs: { anchor: "BMAP_ANCHOR_TOP_LEFT" } }),
            ],
            2
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _vm.actions
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.saveMapSelect },
                    },
                    [_vm._v(_vm._s(_vm.$t("commons.save")))]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }