<template>
  <el-select
    v-model="selectedValues"
    style="width: 100%"
    filterable
    clearable
    :size="size"
    :remote-method="filterAccounts"
    :multiple="multiple"
    :multiple-limit="limit"
    :value-key="valueField"
    remote
    ref="select"
    @hook:mounted="cancalReadOnly"
    @visible-change="cancalReadOnly"
    @change="changeAccount"
    :loading="loading"
    :placeholder="$t('commons.searchAccount')"
  >
    <el-option
      v-for="item in accountList"
      :key="item.userAccount"
      :label="item.userName ? item.userName : item.userAccount"
      :value="item"
    >
      <span style="float: left">{{
        item.userName ? item.userName : item.userAccount
      }}</span>
      <span style="float: right; color: #8492a6; font-size: 13px">{{
        item.userAccount
      }}</span>
    </el-option>
  </el-select>
</template>

<script>
import { envInfo } from "@/constants/envInfo";
import request from "@/utils/request";

export default {
  name: "AccountSelect",
  data() {
    return {
      selectedValues: null,
      loading: false,
      fitlerTimer: null,
      accountList: [],
      emptyList: null,
      fiexdAccounts: [],
      call: false,
      service: envInfo.bgApp.tenantPath + "/tenant/user/userList?filter=",
    };
  },
  props: {
    multiple: {
      //是否能够多选
      type: Boolean,
      default: false,
      required: false,
    },
    limit: {
      //最多能选多少
      type: Number,
      default: 0,
      required: false,
    },
    value: {
      //子组件绑定值
      required: false,
    },
    valueField: {
      //指定绑定值的字段
      default: "userAccount",
      required: false,
    },
    model: {
      //true:按当前租户过滤；false:所有
      default: 0, //默认当前租户过滤
      required: false,
    },
    size: {
      default: "medium",
      required: false,
    },
  },
  watch: {
    value: {
      handler(val) {
        if (!this.call) {
          this.selectedValues = val ? val : [];
          this.filterAccounts(val);
        }
        this.call = false;
      },
      immediate: true,
    },
  },
  created() {
    if (this.model == 1) {
      this.service = envInfo.bgApp.ssoPath + "/user/userList?filter=";
    }
  },
  methods: {
    cancalReadOnly(onOff) {
      console.log(11111);
      this.$nextTick(() => {
        if (!onOff) {
          const { select } = this.$refs;
          const input = select.$el.querySelector(".el-input__inner");
          input.removeAttribute("readonly");
        }
      });
    },
    filterAccounts(query) {
      this.loading = true;
      this.fitlerTimer && clearTimeout(this.fitlerTimer);
      this.fitlerTimer = setTimeout(() => {
        if (!query && this.emptyList) {
          this.accountList = this.emptyList;
        } else {
          request({
            url: this.service + encodeURIComponent(query),
            method: "get",
          }).then((res) => {
            //        	console.log(188888,JSON.parse(JSON.stringify(res)))
            if (res) {
              this.loading = false;
              this.accountList = this.handleMutilAccount(res);
              this.emptyList = JSON.parse(JSON.stringify(this.accountList));
            }
          });
        }
      }, 500);
    },
    handleMutilAccount(accounts) {
      let fiexds = [];
      fiexds.push(this.fiexdAccounts);
      for (let i = 0; i < fiexds.length; i++) {
        for (let j = 0; j < accounts.length; j++) {
          if (fiexds[i].userAccount == accounts[j].userAccount) {
            fiexds[i] = null;
            break;
          }
        }
      }
      let fixedValues = fiexds.filter((item) => item);
      return accounts.concat(fixedValues);
    },
    selected(newVal) {
      this.call = true;
      let selectedValue = null;
      if (newVal) {
        if (this.multiple) {
          selectedValue = newVal.map((m) => {
            return m[this.valueField];
          });
        } else {
          selectedValue = newVal[this.valueField];
        }
      }
      this.fiexdAccounts = newVal;
      this.$emit("input", selectedValue);
    },
    changeAccount(selected) {
      this.selected(selected);
      this.$emit("change", selected);
      this.$emit("select", selected);
    },
  },
};
</script>
