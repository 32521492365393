var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pagination-container" }, [
    _vm.value.total > 0
      ? _c(
          "div",
          [
            _c("el-pagination", {
              attrs: {
                background: _vm.background,
                "current-page": _vm.value.current,
                "page-sizes": [10, 20, 30, 40],
                "pager-count": _vm.value.pagerCount,
                "page-size": _vm.value.rowCount,
                layout: _vm.layoutOptions(),
                total: Number(_vm.value.total),
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange,
                "update:currentPage": function ($event) {
                  return _vm.$set(_vm.value, "current", $event)
                },
                "update:current-page": function ($event) {
                  return _vm.$set(_vm.value, "current", $event)
                },
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }