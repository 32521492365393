<template>
  <div class="tab-bind-comp">
    <el-row style="margin-bottom: 10px" :hidden="tagValueList.length == 0">
      <el-tag
        :key="index"
        v-for="(tag, index) in tagValueList"
        closable
        size="normal"
        :disable-transitions="false"
        @close="handleTagDelete(tag)"
      >
        {{ tag }}
      </el-tag>
    </el-row>
    <el-row>
      <template v-if="inputVisible">
        <el-autocomplete
          class="input-new-tag"
          v-model="inputValue"
          :fetch-suggestions="querySearch"
          style="min-width: 150px"
          ref="saveTagInput"
          size="small"
          :maxlength="maxlength"
          clearable
          :select-when-unmatched="true"
          :placeholder="$t('commons.pleaseInput')"
          @select="handleInputConfirm"
          @blur="inputBlur"
        >
        </el-autocomplete>
        <!-- <el-button
          type="success"
          @click="handleInputConfirm"
          icon="el-icon-check"
          size="mini"
          style="margin-left: 10px"
          circle
        ></el-button>
        <el-button
          type="danger"
          icon="el-icon-close"
          @click="inputVisible = false"
          size="mini"
          style="margin-left: 10px"
          circle
        ></el-button> -->
      </template>
      <el-button v-if='!inputVisible && tagValueList.length<limit' class="button-new-tag" size="small" @click="showInput"
        >+ {{ $t("tag.create") }}</el-button
      >
    </el-row>
  </div>
</template>

<script>
import { getTagList } from "@/api/ruge/tag/tag";

export default {
  name: "TagBind",
  props: {
    tagValueList: {
      type: Array,
      required: true,
    },
    tagType: {
      type: String,
      required: true,
    },
    limit: {
      type: Number,
      default:99
    },
    maxlength: {
      type: Number,
      default:20
    },
    notAuto:{
      type: Boolean,
      default:false
    },
  },
  data() {
    return {
      tagList: [],
      suggestList: null, //缓存建议列表
      inputVisible: false,
      inputValue: "",
      listQuery: {
        //分页参数
        current: 1,
        rowCount: 20,
        resultMode: 2,
        tagType: null,
      },
    };
  },
  methods: {
    initTagList() {
      this.listQuery.tagType = this.tagType;
      return getTagList(this.listQuery)
        .then((res) => {
          this.tagList = [];
          let tagList = [];
          res.rows.map((v) => {
            tagList.push(v.tagName);
          });
          let tagValueList = this.tagValueList;
          let set1 = new Set(tagList);
          let set2 = new Set(tagValueList);
          let dfrcset = [...new Set([...set1].filter((x) => !set2.has(x)))];
          dfrcset.map((v) => {
            this.tagList.push({ value: v });
          });
        })
        .catch((error) => {
          console.log(`查询失败，原因=> ${error}`);
        });
    },
    //删除标签页
    handleTagDelete(tag) {
      if (this.tagList.findIndex((item) => item.value == tag) == -1) {
        this.tagList.push({ value: tag });
      }
      this.tagValueList.splice(this.tagValueList.indexOf(tag), 1);
    },
    showInput() {
      this.inputValue = "";
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      let inputValue = this.inputValue.trim();
      if (inputValue.includes(",")) {
        this.$message.error("标签中不能含有逗号！");
        return;
      }
      if (inputValue) {
        this.tagValueList.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    inputBlur() {
      // 延迟关闭，不然在正常选择的时候会有问题。
      setTimeout(() => {
        // this.inputValue = "";
        // this.inputVisible = false;
        this.handleInputConfirm();
      }, 200);
    },
    handleSelect(item) {
      let inputValue = item.value.trim();
      console.log("inputValue", inputValue);
      if (inputValue) {
        this.tagValueList.push(inputValue);
        this.inputVisible = false;
      }
    },
    querySearch(queryString, cb) {
      if (!this.inputValue && this.suggestList) {
        cb(this.suggestList);
        return;
      }
      if(this.notAuto){
      	cb([]);
    		return ;
    	}
      this.initTagList().then(() => {
        let tagList = this.tagList;
        let results = queryString
          ? tagList.filter(this.createFilter(queryString))
          : tagList;
        // 调用 callback 返回建议列表的数据
        !this.suggestList && (this.suggestList = results);
        cb(results);
      });
    },
    createFilter(queryString) {
      return (tag) => {
        return tag.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    },
  },
};
</script>

<style scoped>
.tab-bind-comp {
  display: flex;
}
.button-new-tag {
  height: 32px;
  line-height: 32px;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2a4158;
  position: relative;
  top: 1px;
}
.el-tag {
  margin-right: 10px;
  margin-bottom: 10px;
}
.input-new-tag {
  vertical-align: bottom;
}
</style>
