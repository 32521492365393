<template>
	<div>
		<el-table-column v-for='(l2,i2) in datas' :key='i2' 
			:prop="l2.prop" 
			:label="l2.label?$t(l2.label):l2.name"
            :show-overflow-tooltip="l2.showOverflowTooltip ? l2.showOverflowTooltip : true"
            :width="l2.width ? l2.width : ''"
            :align="l2.align ? l2.align : ''"
            :sortable="l2.sortable ? l2.sortable : false"
			>
            <TColumns v-if='l2.childs' :datas='l2.childs'></TColumns>
		</el-table-column>
	</div>
</template>

<script>
	export default {
		name: 'TColumns',
		props: {
			datas:{
				type:Array,
	            default:()=>{
	                return []
	            },
			},
		},
		data() {
			return {}
		},
		mounted() {},
		methods: {
			handleOpen() {},
			handleClose() {},
		}
	}
</script>

<style>

</style>