var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tab-bind-comp" },
    [
      _c(
        "el-row",
        {
          staticStyle: { "margin-bottom": "10px" },
          attrs: { hidden: _vm.tagValueList.length == 0 },
        },
        _vm._l(_vm.tagValueList, function (tag, index) {
          return _c(
            "el-tag",
            {
              key: index,
              attrs: {
                closable: "",
                size: "normal",
                "disable-transitions": false,
              },
              on: {
                close: function ($event) {
                  return _vm.handleTagDelete(tag)
                },
              },
            },
            [_vm._v("\n      " + _vm._s(tag) + "\n    ")]
          )
        }),
        1
      ),
      _c(
        "el-row",
        [
          _vm.inputVisible
            ? [
                _c("el-autocomplete", {
                  ref: "saveTagInput",
                  staticClass: "input-new-tag",
                  staticStyle: { "min-width": "150px" },
                  attrs: {
                    "fetch-suggestions": _vm.querySearch,
                    size: "small",
                    maxlength: _vm.maxlength,
                    clearable: "",
                    "select-when-unmatched": true,
                    placeholder: _vm.$t("commons.pleaseInput"),
                  },
                  on: { select: _vm.handleInputConfirm, blur: _vm.inputBlur },
                  model: {
                    value: _vm.inputValue,
                    callback: function ($$v) {
                      _vm.inputValue = $$v
                    },
                    expression: "inputValue",
                  },
                }),
              ]
            : _vm._e(),
          !_vm.inputVisible && _vm.tagValueList.length < _vm.limit
            ? _c(
                "el-button",
                {
                  staticClass: "button-new-tag",
                  attrs: { size: "small" },
                  on: { click: _vm.showInput },
                },
                [_vm._v("+ " + _vm._s(_vm.$t("tag.create")))]
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }