var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.datas, function (l2, i2) {
      return _c(
        "el-table-column",
        {
          key: i2,
          attrs: {
            prop: l2.prop,
            label: l2.label ? _vm.$t(l2.label) : l2.name,
            "show-overflow-tooltip": l2.showOverflowTooltip
              ? l2.showOverflowTooltip
              : true,
            width: l2.width ? l2.width : "",
            align: l2.align ? l2.align : "",
            sortable: l2.sortable ? l2.sortable : false,
          },
        },
        [
          l2.childs
            ? _c("TColumns", { attrs: { datas: l2.childs } })
            : _vm._e(),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }